<template>
	<div class="mypage_content_wrap">
		<h2>정보수정</h2>
		<div class="mypage_content_box_wrap d_flex2">
			<div class="mypage_content_box w-49pst">
				<div class="mypage_tit_wrap">
					<h4>기본정보</h4>
					<p><span class="point_star">*</span>는 필수 입력 사항입니다.</p>
				</div>
				<div class="form_box_wrap">
					<label for="id">
						<div class="form_box_tit">
							<h5>회원 아이디(이메일주소)</h5>
						</div>
					</label>
					<input type="text" id="id" name="id" placeholder="아이디 변경 불가능" class="mgb0" disabled="" />
					<div class="explanation">
						이메일 주소(계정 아이디)는 <span class="red">변경이 불가능</span>합니다.
					</div>
				</div>
				<div class="form_box_wrap">
					<label for="password">
						<div class="form_box_tit">
							<h5>기존 비밀번호</h5>
							<span class="point_star">*</span>
						</div>
					</label>
					<input
						v-model="origin_pw"
						type="password"
						placeholder="현재 비밀번호를 입력해주세요."
						@keyup="origin_pw_check()"
						:disabled="snsState"
					/>
					<p id="modify_temp_origin"></p>
					<ul class="explanation">
						<li>정보 변경을 원하시면 현재 비밀번호를 입력해주세요.</li>
						<li style="color: red">SNS 로그인 유저는 입력할 수 없습니다.</li>
					</ul>
				</div>
				<div class="form_box_wrap">
					<label for="password">
						<div class="form_box_tit">
							<h5>비밀번호 변경</h5>
						</div>
					</label>
					<input
						v-model="current_pw"
						type="password"
						placeholder="새 비밀번호를 입력해주세요. "
						@keyup="pw_check()"
						:disabled="snsState"
					/>
					<input
						v-model="current_pw_ck"
						type="password"
						placeholder="새 비밀번호를 다시 입력해주세요. "
						class="mgb0"
						@keyup="pw_second_check()"
						:disabled="snsState"
					/>
					<p id="modify_temp_text"></p>
					<ul class="explanation">
						<li>새 비밀번호와 새 비밀번호 확인은 변경을 원하시는 경우에만 입력해 주세요.</li>
						<li>회원님의 소중한 개인 정보를 보호하기 위해 비밀번호는 주기적으로 변경해 주세요.</li>
						<li>비밀번호는 <span class="red">8~32자의 영문+숫자+특수문자</span> 조합 이어야 합니다.</li>
						<li style="color: red">SNS 로그인 유저는 입력할 수 없습니다.</li>
					</ul>
				</div>
				<div class="form_box_wrap">
					<label for="email">
						<div class="form_box_tit">
							<h5>추가 이메일주소</h5>
						</div>
					</label>
					<input type="text" name="email" placeholder="아이디 변경 불가능" class="mgb0" disabled="" />
					<div class="explanation"><span class="red">아이디(이메일) 찾기용</span>으로 사용됩니다.</div>
				</div>
			</div>
			<div class="mypage_content_box w-49pst">
				<div class="mypage_tit_wrap">
					<h4>추가정보</h4>
					<p><span class="point_star">*</span>는 필수 입력 사항입니다.</p>
				</div>
				<div class="form_box_wrap">
					<label for="name">
						<div class="form_box_tit">
							<h5>닉네임</h5>
						</div>
					</label>
					<input
						type="text"
						name="nickname"
						placeholder=""
						v-model="setting_nickname"
						id="modify_nickname"
						class="mgb0"
					/>
					<div class="explanation">닉네임은<span class="red">10자</span>이내로 기입해주세요.</div>
				</div>
				<div class="form_box_wrap">
					<div class="form_box_tit">
						<h5>성별</h5>
						<div class="checkbox_wrap">
							<input type="radio" v-model="sex" value="W" class="g_check" id="g_check1" /><label
								for="g_check1"
								>여자</label
							>
							<input type="radio" v-model="sex" value="M" class="g_check" id="g_check2" /><label
								for="g_check2"
								>남자</label
							>
						</div>
					</div>
				</div>
				<div class="form_box_wrap">
					<button
						v-if="!user_certify_info"
						@click="user_certify()"
						type="button"
						class="btn_basic btn_green_bg"
					>
						<span class="text">본인인증</span>
					</button>
					<div v-if="user_certify_info">
						<button style="cursor: not-allowed" disabled type="button" class="btn_basic btn_grey_bg">
							<span class="text">본인인증완료</span>
						</button>
						<p id="certify_text">인증이 완료된 계정입니다.</p>
						<div class="explanation">
							새로운 인증을 원하시면
							<span class="red"><a style="cursor: pointer" @click="user_certify()">재전송</a></span
							>을 클릭해 주세요.
						</div>
					</div>
				</div>

				<div @click="router.push('/mypage/secession')" class="secession_btn">회원탈퇴</div>
			</div>
		</div>
		<div class="form_box_wrap bg_grey_box">
			<div class="form_box_tit">
				<h5>이벤트 혜택 및 마케팅 정보 알림</h5>
				<div class="checkbox_wrap">
					<input
						type="checkbox"
						@click="event_alarm_Y_click()"
						v-model="event_alarm_Y"
						class="g_check"
						id="g_check3"
					/><label for="g_check3">수신</label>
					<input
						type="checkbox"
						@click="event_alarm_N_click()"
						v-model="event_alarm_N"
						class="g_check"
						id="g_check4"
					/><label for="g_check4">거부</label>
				</div>
			</div>
		</div>

		<div class="btn_wrap_line">
			<button type="button" @click="router.go(-1)" class="btn_basic btn_grey_bg">취소</button>
			<button type="button" @click="modify_send()" class="btn_basic btn_green_bg">저장</button>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { userInfoStore } from '@/store/UserStore';
import { PW_REGI } from '../membership/Register';
import ajax from '@/api/api-manager';
import { useRouter } from 'vue-router';
import { getLoginInfo, checkLogin } from '@/common-methods/checkAccount';
import jwt from '@/api/LocalStorage';

const origin_pw = ref('');
const current_pw = ref('');
const current_pw_ck = ref('');
const password_valid = ref(true);
const user_certify_info = ref(false);
const setting_nickname = ref('');
const event_alarm_Y = ref('');
const event_alarm_N = ref('');
const store = userInfoStore();
const router = useRouter();
const sex = ref(null);
const loginState = ref(checkLogin());
const loginType = ref(jwt.getToken('loginType'));
const snsState = ref();
if (loginType.value == 'kakao' || loginType.value == 'naver') {
	snsState.value = true;
} else {
	snsState.value = false;
}

onMounted(() => {
	if (loginState.value) {
		store.user_info();
		setTimeout(() => {
			console.log(store.user_info_list);
			setting_user_info();
		}, 100);
		ajax(`user/user_certify_info`)
			.then((res) => {
				console.log(res);
				user_certify_info.value = res.data.data;
			})
			.catch((err) => {
				console.log(err.response.data);
			});
	} else {
		alert('로그인 먼저 진행해주세요');
		router.push('/login');
	}
});

const user_certify = () => {
	const IMP = window.IMP;
	IMP.init('imp71379418');
	let merchant_uid = 'merchant_' + new Date().getTime();
	IMP.certification(
		{
			merchant_uid, // 주문 번호
			popup: false,
			m_redirect_url: `${window.location.host}/certification/complete`,
		},
		function (rsp) {
			// console.log('');
			// callback
			if (rsp.success) {
				const form = new FormData();
				form.append('imp_uid', rsp.imp_uid);
				// 인증 성공 시 로직,
				ajax(`user/user_certify`, form)
					.then((res) => {
						console.log(res);
						// user_certify_info.value = true;
						window.location.reload();
					})
					.catch((err) => {
						console.log(err.response.data);
						alert('본인인증에 실패하였습니다.');
					});
			}
		},
	);
};

//정보수정 완료 버튼
const modify_send = () => {
	var formData = new FormData();
	//폼데이터 생성 필수 입력 사항 및 조건이 많아 하나하나 append 시켜줫다.
	formData.append('nickname', setting_nickname.value);
	formData.append('sex', sex.value);
	//비밀번호 빈값이 아닐때
	if (current_pw_ck.value !== null && current_pw.value !== null && origin_pw.value !== null) {
		formData.append('oldPw', origin_pw.value);
		formData.append('password', current_pw_ck.value);
	} else if (current_pw_ck.value === null && current_pw.value === null && origin_pw.value === null) {
		formData.append('oldPw', origin_pw.value);
		formData.append('password', '');
	}
	const event_alarm = () => {
		if (event_alarm_Y.value === true) {
			formData.append('event_alarm_yn', 'Y');
		} else if (event_alarm_N.value === true) {
			formData.append('event_alarm_yn', 'N');
		} else {
			formData.append('event_alarm_yn', '');
		}
	};
	event_alarm();
	//필수입력사항은 기존 비밀번호 하나!
	if (origin_pw.value !== '' || snsState.value) {
		ajax(`mypage/user_info_modify`, formData)
			.then((res) => {
				console.log(res);
				alert('기본정보 수정이 완료되었습니다 !');
				router.go();
			})
			.catch((err) => {
				if (err.response.data.code === 'M003') {
					alert('기존 비밀번호를 확인해주세요!!');
				}
			});
	} else {
		alert('기존 비밀번호를 확인해주세요!!');
	}
};

//기존의 유저정보를 화면에 표시해주는 메소드
//스토어에 저장된 유저정보 가져오기
const setting_user_info = () => {
	setting_nickname.value = store.user_info_list.nickname;
	sex.value = store.user_info_list.sex;
	if (store.user_info_list.event_alarm_yn === 'Y') {
		event_alarm_N.value = false;
		event_alarm_Y.value = true;
	} else if (store.user_info_list.event_alarm_yn === 'N') {
		event_alarm_N.value = true;
		event_alarm_Y.value = false;
	}
};

//클릭시 하나만 선택되게끔
const event_alarm_Y_click = () => {
	event_alarm_N.value = false;
	event_alarm_Y.value = true;
};
const event_alarm_N_click = () => {
	event_alarm_N.value = true;
	event_alarm_Y.value = false;
};

//기존 비밀번호 유효성 검사
const origin_pw_check = () => {
	const temp_text = document.querySelector('#modify_temp_origin');
	temp_text.style.color = '#ff0000';
	// 정규식 표현 사용하여 패스워드 검사 진행
	const pw_regi = PW_REGI.test(origin_pw.value);
	if (origin_pw.value.length < 8 || origin_pw.value.length > 32) {
		temp_text.innerHTML = '비밀번호는 8 ~ 32자리 이내로 입력해주세요.';
	} else if (origin_pw.value.search(/\s/) != -1) {
		temp_text.innerHTML = '비밀번호는 공백 없이 입력해주세요.';
	} else if (!pw_regi) {
		temp_text.innerHTML = '비밀번호는 영문,숫자, 특수문자를 혼합하여 입력해주세요.';
	} else {
		temp_text.innerHTML = '';
	}
};
//새로운 비밀번호 유효성 검사
const pw_check = () => {
	const temp_text = document.querySelector('#modify_temp_text');
	temp_text.style.color = '#ff0000';
	// 정규식 표현 사용하여 패스워드 검사 진행
	const pw_regi = PW_REGI.test(current_pw.value);
	if (current_pw.value.length < 8 || current_pw.value.length > 32) {
		temp_text.innerHTML = '비밀번호는 8 ~ 32자리 이내로 입력해주세요.';
	} else if (current_pw.value.search(/\s/) != -1) {
		temp_text.innerHTML = '비밀번호는 공백 없이 입력해주세요.';
	} else if (!pw_regi) {
		temp_text.innerHTML = '비밀번호는 영문,숫자, 특수문자를 혼합하여 입력해주세요.';
	} else if (current_pw.value === origin_pw.value) {
		temp_text.innerHTML = '현재 비밀번호와 새 비밀번호가 같습니다.';
	} else {
		temp_text.innerHTML = '';
	}
};

//패스워드 더블체킹 로직
const pw_second_check = () => {
	const temp_text = document.querySelector('#modify_temp_text');
	temp_text.style.color = '#ff0000';
	const pw_second_regi = PW_REGI.test(current_pw_ck.value);
	if (current_pw_ck.value.length < 8 || current_pw_ck.value.length > 32) {
		temp_text.innerHTML = '비밀번호는 8 ~ 32자리 이내로 입력해주세요.';
	} else if (current_pw_ck.value.search(/\s/) != -1) {
		temp_text.innerHTML = '비밀번호는 공백 없이 입력해주세요.';
	} else if (!pw_second_regi) {
		temp_text.innerHTML = '비밀번호는 영문,숫자, 특수문자를 혼합하여 입력해주세요.';
	} else {
		temp_text.innerHTML = '';
	}
	if (current_pw_ck.value === '') {
		temp_text.innerHTML = '비밀번호를 입력하세요';
	} else if (current_pw.value !== current_pw_ck.value) {
		temp_text.innerHTML = '비밀번호가 다릅니다.';
	} else if (current_pw.value === origin_pw.value) {
		temp_text.innerHTML = '현재 비밀번호와 새 비밀번호가 같습니다.';
	} else {
		temp_text.innerHTML = '비밀번호가 일치합니다.';
		temp_text.style.color = 'blue';
		password_valid.value = true;
	}
};
</script>
<style scoped>
#modify_temp_text {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
}
#modify_temp_origin {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
}
#certify_text {
	font-size: 12px;
	color: #09ca91;
	opacity: 0.8;
	margin-top: 5px;
}
</style>
